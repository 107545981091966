var { sortBy } = require('lodash');
import { TeachingDare } from '../models/TeachingDare';
// import imageToBase64 from "image-to-base64";

// const numToWord = {
//     '1': 'หนึ่ง',
//     '2': 'สอง',
//     '3': 'สาม',
//     '4': 'สี่',
//     '5': 'ห้า',
//     '6': 'หก',
//     '7': 'เจ็ด',
//     '8': 'แปด',
//     '9': 'เก้า',
//     '0': 'ศูนย์',
// }

const LESSON_NAME_MAPPING = {
    "วัตถุประสงค์และภาพรวมของหลักสูตร D.A.R.E.": "วัตถุประสงค์และภาพรวมของหลักสูตร D.A.R.E.",
    "บุหรี่กับนักเรียน": "บุหรี่กับนักเรียน",
    "ม่านควัน": "ม่านควัน",
    "เหล้ากับนักเรียน": "เหล้ากับนักเรียน",
    "ความจริง": "ความจริง",
    "รากฐานของมิตรภาพ": "รากฐานของมิตรภาพ",
    "การรวมทุกสิ่งเข้าด้วยกัน": "การรวมทุกสิ่งเข้าด้วยกัน",
    "การกระทำของแต่ละคน": "การกระทำของแต่ละคน",
    "ฝึก ฝึก และฝึก": "ฝึก ฝึก และฝึก",
    "การป้องกันอาชญากรรมด้วยตนเอง": "การป้องกันอาชญากรรมด้วยตนเอง",
    "ความรู้เกี่ยวกับการจราจร": "ความรู้เกี่ยวกับการจราจร",
    "โรงพักของเรา": "โรงพักของเรา",
    "การสร้างจิตสำนึกปลูก ฝังคุณธรรม จริยธรรมแก่เด็ก และเยาวชน": "การสร้างจิตสำนึกปลูก ฝังคุณธรรม จริยธรรมแก่เด็ก และเยาวชน",
    "การพัฒนาจิตใจ ให้เข้มแข็ง มีความคิดสร้างสรรค์": "การพัฒนา\nจิตใจ\nให้เข้มแข็ง\nมีความคิด\nสร้างสรรค์",
    "ส่งเสริมให้เด็ก และเยาวชนดำรง ชีวิตตามหลัก ปรัชญาเศรษฐกิจพอเพียง": "ส่งเสริมให้เด็ก และเยาวชนดำรง ชีวิต\nตามหลักปรัชญา\nเศรษฐกิจ\nพอเพียง",
    // update 5/21/2022
    "ความกตัญญู": "ความกตัญญู",
    "จิตสาธารณะ": "จิตสาธารณะ",
    "เศรษฐกิจพอเพียง": "เศรษฐกิจพอเพียง",
    "กิจกรรมพิเศษ": "กิจกรรมพิเศษ",
};

const LESSON_CONTENT_MAPPING = {
    "วัตถุประสงค์และภาพรวมของหลักสูตร D.A.R.E.": "เป็นการแนะนำโครงการ D.A.R.E. ประเทศไทย\n ให้กับเด็กนักเรียนในชั่วโมงแรก อธิบาย\nถึงองค์ประกอบต่างๆ ของหลักสูตร D.A.R.E.\n เมื่อจบชั่วโมงการสอนนักเรียนสามารถเข้าใจ\nสาระสำคัญของหลักสูตรD.A.R.E. และอธิบาย\nขั้นตอนรูปแบบการตัดสินใจแบบ D.A.R.E. ได้",
    "บุหรี่กับนักเรียน": "เป็นการเปลี่ยนความเชื่อของเด็กนักเรียนให้รู้\nความจริงว่าเด็กนักเรียนส่วนใหญ่ไม่สูบบุหรี่\nเพื่อช่วยเด็กนักเรียนให้สามารถพัฒนา\nความรู้พื้นฐานเกี่ยวกับบุหรี่และผลร้ายของมัน\nเมื่อผ่านการเรียนบทนี้แล้วเด็กนักเรียนสามารถ\nระบุผลร้ายของบุหรี่ที่มีต่อร่างกายและ\nสามารถใช้รูปแบบการตัดสินใจแบบ D.A.R.E. เมื่อถูกชักชวนให้สูบบุหรี่ได้",
    "ม่านควัน": "เพื่อให้เด็กนักเรียนเรียนรู้ข้อเท็จจริงเกี่ยวกับ\nผลร้ายของกัญชา ซึ่งเป็นการสูบอีกชนิดหนึ่ง\nรวมทั้งความแตกต่างของพิษภัยในบุหรี่\nและกัญชาที่ส่งผลร้ายต่อร่างกายอีกด้วย",
    "เหล้ากับนักเรียน": "เพื่อให้เด็กนักเรียนได้เรียนรู้ข้อเท็จจริง\nและผลกระทบของการดื่มเหล้าที่มีต่อสมอง\nและร่างกาย สามารถใช้รูปแบบการตัดสินใจ\nแบบ D.A.R.E. เมื่อต้องเผชิญกับสถานการณ์\nการถูกชวนให้ดื่มเหล้า",
    "ความจริง": "เด็กนักเรียนได้เรียนรู้ความจริงและผลกระทบที่\nเกิดจากโฆษณาเหล้า นำเสนอเนื้อหาเกี่ยวกับ\nสารระเหย เหล้า บุหรี่ และยาเสพติดอื่น ๆ\n - บทเรียนนี้เด็กนักเรียนจะได้ทำกิจกรรม\nแข่งขันผลัดกันตอบ โดยระบุผลร้ายจาก\nยาเสพติดประเภทต่าง ๆ และระบุกิจกรรม\nสร้างสรรค์ที่ไม่ยุ่งเกี่ยวกับยาเสพติด",
    "รากฐานของมิตรภาพ": "นักเรียนได้เรียนรู้คุณสมบัติของเพื่อนที่ดี\nและเพื่อนที่ไม่ดี ความหมายของแรงกดดัน\nจากเพื่อนและการแสดงบทบาทสมมุติ \nโดยใช้รูปแบบการตัดสินใจแบบ D.A.R.E. \nเพื่อโต้ตอบแรงกดดันจากเพื่อนประเภทต่าง ๆ",
    "การรวมทุกสิ่งเข้าด้วยกัน": "การฝึกทักษะการปฏิเสธยาเสพติด \nหลีกเลี่ยงความรุนแรง / สถานการณ์เสี่ยง\n เพื่อดูแลรับผิดชอบชีวิตตนเอง โดยใช้รูปแบบ\nการโต้ตอบแบบมั่นใจ และแสดงความสามารถ\nในการประยุกต์ใช้ขั้นตอนการตัดสินใจได้",
    "การกระทำของแต่ละคน": "การอธิบายถึงแรงกดดันจากภายในที่มีอิทธิพล\nต่อการตัดสินใจเกี่ยวกับการใช้ยาเสพติด \nทบทวนบทเรียนหลักสูตร D.A.R.E. \nเพื่อนำข้อมูลมาเขียนเรียงความ D.A.R.E.",
    "ฝึก ฝึก และฝึก": "เป็นการประยุกต์ใช้รูปแบบการตัดสินใจแบบ\n D.A.R.E. วิธีการดูแลรับผิดชอบตนเอง \nและการโต้ตอบอย่างมั่นใจในสถานการณ์\nเสี่ยงสำหรับการใช้ชีวิตประจำวันของเด็กนักเรียน",
    "การป้องกันอาชญากรรมด้วยตนเอง": "นักเรียนสามารถหลีกเลี่ยงภัยจาก\nการก่ออาชญากรรม สาเหตุของการเกิด\nอาชญากรรม รู้วิธีหลีกเลี่ยงอาชญากรรม\n สถานการณ์การก่ออาชญากรรมในปัจจุบัน",
    "ความรู้เกี่ยวกับการจราจร": "การใช้รถใช้ถนนอย่างปลอดภัยใน \n- รู้กฎหมายจราจรที่เกี่ยวข้อง รู้เรื่องเกี่ยวกับ\nเครื่องหมายจราจรที่สำคัญ รู้มาตรการ\nการขับขี่อย่างปลอดภัย การแก้ปัญหาเบื้องต้น\nเมื่อประสบอุบัติเหตุ",
    "โรงพักของเรา": "- เด็กนักเรียนได้เรียนรู้ระบบการทำงานของตำรวจ\nวิธีติดต่องานกับเจ้าหน้าที่ตำรวจรวมถึงระบบงาน\nยุติธรรมเบื้องต้น",
    "การสร้างจิตสำนึกปลูก ฝังคุณธรรม จริยธรรมแก่เด็ก และเยาวชน": "- กิจกรรมที่ ๑ กล่อง D.A.R.E. ๓ นาที\n- กิจกรรมที่ ๒ ทบทวนบทเรียนที่ผ่านมา ๒ นาที\n- กิจกรรมที่ ๓ พื้นฐานความเข้าใจเรื่องจิตสำนึก ๑๐ นาที\n- กิจกรรมที่ ๔ ชมวิดิทัศน์ น้องน้ำฝน ๑๕ นาที\n- กิจกรรมที่ ๕ ชมวิดิทัศน์การช่วยเหลือ\nและแบ่งปัน ๑๐ นาที\n- กิจกรรมที่ ๖ ต้นกล้าแห่งความดี ๕ นาที\n- กิจกรรมที่ ๗ ทบทวนและจบกิจกรรม ๕ นาที",
    "การพัฒนาจิตใจ ให้เข้มแข็ง มีความคิดสร้างสรรค์": "- กิจกรรมที่ ๑ กล่อง D.A.R.E. ๓ นาที\n- กิจกรรมที่ ๒ ทบทวนบทเรียนที่ผ่านมา ๒ นาที\n- กิจกรรมที่ ๓ พื้นฐานความเข้าใจเรื่องจิตใจ ๑๐ นาที\n- กิจกรรมที่ ๔ ชมวิดิทัศน์ ห้องเรียนจากกองขยะ ๑๕ นาที\n- กิจกรรมที่ ๕ ชมวิดิทัศน์ เรื่องขี้ๆ ๑๐ นาที\n- กิจกรรมที่ ๖ ต้นกล้าแห่งความดี ๕ นาที\n- กิจกรรมที่ ๗ ทบทวนและจบกิจกรรม ๕ นาที",
    "ส่งเสริมให้เด็ก และเยาวชนดำรง ชีวิตตามหลัก ปรัชญาเศรษฐกิจพอเพียง": "- กิจกรรมที่ ๑ กล่อง D.A.R.E. ๓ นาที\n- กิจกรรมที่ ๒ ทบทวนบทเรียนที่ผ่านมา ๒ นาที\n- กิจกรรมที่ ๓ พื้นฐานความเข้าใจ\nเรื่องเศรษฐกิจพอเพียง ๑๐ นาที\n- กิจกรรมที่ ๔ ชมวิดิทัศน์ ความสุข ๑๕ นาที\n- กิจกรรมที่ ๕ ชมวิดิทัศน์ คนจนผู้ยิ่งใหญ่ ๑๐ นาที\n- กิจกรรมที่ ๖ ต้นกล้าแห่งความดี ๕ นาที\n- กิจกรรมที่ ๗ ทบทวนและจบกิจกรรม ๕ นาที",
    "ความกตัญญู": "นักเรียนได้เรียนรู้ถึงความกตัญญู การรู้สึกสำนึกในคุณ\n ด้วยแสดงความเคารพ นับถือ เชื่อฟัง และช่วยเหลือใน\nกิจการงานต่างๆ ความกตัญญูเป็นเครื่องหมายของคนดี\n การที่เยาวชนไทยได้รับการปลูกฝังคุณลักษณะที่ดีใน\nเรื่องของความกตัญญูต่อผู้มีพระคุณถือเป็นเรื่องที่ดี\n เป็นการสร้างภูมิคุ้มกันให้สังคม การเลี้ยงดูของ\nครอบครัวเป็นสิ่งสำคัญที่จะช่วยอบรมและส่งเสริม\nพฤติกรรมของเยาวชนให้มีคุณลักษณะของการเป็นคน\nมีความกตัญญู ความตระหนักรู้ในคุณของบุคคล สัตว์ \nและสิ่งแวดล้อมที่มีผลต่อตนเองทั้งโดยตรง\nและโดยอ้อม",
    "จิตสาธารณะ": "นักเรียนได้เรียนรู้ถึงจิตสาธารณะ และจิตสำนึก\nที่ดีในการรู้จักหน้าที่ ความรับผิดชอบ เสียสละ\nเพื่อประโยชน์ของส่วนรวม ยึดมั่นทั้งในด้าน\nคุณธรรม จริยธรรม ตลอดจนร่วมมือในการ\nแก้ไขปัญหาต่างๆ โดยไม่ขัดต่อกฎหมาย\nลักษณะของผู้ที่มีจิตสาธารณะ จะต้องเป็น\nผู้ที่ให้ และคอยช่วยเหลือผู้อื่นที่เดือดร้อนด้วย\nความเต็มใจ ไม่หวังผลตอบแทน ไม่เห็นแก่ตัว \nเข้าใจผู้อื่น ไม่เป็นคนใจแคบ รับฟังความคิด\nเห็นของผู้อื่น",
    "เศรษฐกิจพอเพียง": "นักเรียนได้เรียนรู้ถึงเศรษฐกิจพอเพียงตามแนวการ\nดำรงอยู่และปฏิบัติตนของประชาชนในทุกระดับ\n เรียนรู้ทางสายกลางความพอประมาณ ความมีเหตุ\nผล รวมถึงความจำเป็นที่จะต้องมีระบบภูมิคุ้มกันใน\nตัวที่ดีพอสมควร ความซื่อสัตย์สุจริตและให้มีความ\nรอบรู้ที่เหมาะสม ดำเนินชีวิตด้วยความอดทน ความ\nเพียร มีสติ ปัญญา และความรอบคอบ เพื่อให้สม\nดุลและพร้อมต่อการรองรับการเปลี่ยนแปลงอย่าง\nรวดเร็วและกว้างขวาง ทั้งด้านวัตถุ สังคม สิ่งแวด\nล้อม และวัฒนธรรมจากโลกภายนอกได้เป็นอย่างดี",
    "กิจกรรมพิเศษ": "การจัดกิจกรรมวันปิดโครงการ D.A.R.E.ประเทศไทย\n เป็นการสร้างความภาคภูมิใจให้กับนักเรียน D.A.R.E. \nโดยเชิญผู้ที่เกี่ยวข้องกับโครงการฯ มาเป็นเกียรติใน\nงาน เช่น ผู้บริหารโรงเรียน คุณครูประจำชั้น ผู้ปก\nครองของเด็กนักเรียน ผู้บังคับบัญชาของครูตำรวจ \nD.A.R.E. และหน่วยงานที่สนับสนุนโครงการ \nD.A.R.E. ประเทศไทย ในพิธีปิดจะมีการอ่านคำ\nปฏิญาณของนักเรียน D.A.R.E. การแสดงของนักเรียน\n D.A.R.E. และการมอบประกาศนียบัตร\nให้แก่นักเรียน D.A.R.E",
};

// const LESSONS = [
//     {
//         lesson_number: "1",
//         lesson_name: "วัตถุประสงค์และภาพรวมของหลักสูตร D.A.R.E.",
//         content: "- แนะนำภาพรวมโครงการ D.A.R.E.\n- แนะนำโครงการ D.A.R.E. ประเทศไทย  \n- องค์ประกอบของหลักสูตร D.A.R.E. \n- ขั้นตอนรูปแบบการตัดสินใจแบบ D.A.R.E.  \n-  การจัดระเบียบในห้องเรียน\n-  การทำความรู้จัก"
//     },
//     {
//         lesson_number: "2",
//         lesson_name: "บุหรี่กับนักเรียน",
//         content: "- เป็นการเปลี่ยนความเชื่อของเด็กนักเรียนให้รู้\nความจริงว่าเด็กนักเรียนส่วนใหญ่ไม่สูบบุหรี่เพื่อ\nช่วยเด็กนักเรียนให้สามารถพัฒนาความรู้พื้นฐาน\nเกี่ยวกับบุหรี่และผลร้ายของมันเมื่อผ่านการเรียน\nบทนี้แล้วเด็กนักเรียนสามารถระบุผลร้ายของบุหรี่\nที่มีต่อร่างกายและสามารถใช้รูปแบบการตัดสินใจ\n แบบD.A.R.E. เมื่อถูกชักชวนให้สูบบุหรี่ได้"
//     },
//     {
//         lesson_number: "3",
//         lesson_name: "ม่านควัน",
//         content: "- ทบทวน และแนะนำบทเรียน\n- เพื่อให้เด็กนักเรียนเรียนรู้ข้อเท็จจริงเกี่ยวกับ\nผลร้ายของกัญชา ซึ่งเป็นการสูบอีกชนิดหนึ่ง\nรวมทั้งความแตกต่างของพิษภัยในบุหรี่และกัญชา\nที่ส่งผลร้ายต่อร่างกายอีกด้วย"
//     },
//     {
//         lesson_number: "4",
//         lesson_name: "เหล้ากับนักเรียน",
//         content: "- เพื่อให้เด็กนักเรียนได้เรียนรู้ข้อเท็จจริง\nและผลกระทบของการดื่มเหล้าที่มีต่อสมอง\nและร่างกาย สามารถใช้รูปแบบการตัดสินใจแบบ D.A.R.E. เมื่อต้องเผชิญกับสถานการณ์\nการถูกชวนให้ดื่มเหล้า"
//     },
//     {
//         lesson_number: "5",
//         lesson_name: "ความจริง",
//         content: "- ทบทวนบทเรียนที่ผ่านมา\n- เด็กนักเรียนได้เรียนรู้ความจริงและผลกระทบ\nที่เกิดจากโฆษณาเหล้า นำเสนอเนื้อหาเกี่ยวกับ\nสารระเหย เหล้า บุหรี่ และยาเสพติดอื่น ๆ\n - บทเรียนนี้เด็กนักเรียนจะได้ทำกิจกรรม\nแข่งขันผลัดกันตอบ โดยระบุผลร้ายจาก\nยาเสพติดประเภทต่าง ๆ และระบุกิจกรรม\nสร้างสรรค์ที่ไม่ยุ่งเกี่ยวกับยาเสพติด"
//     },
//     {
//         lesson_number: "6",
//         lesson_name: "รากฐานของมิตรภาพ",
//         content: "- การแนะนำบทเรียน\n- นักเรียนได้เรียนรู้คุณสมบัติของเพื่อนที่ดี\nและเพื่อนที่ไม่ดี ความหมายของแรงกดดัน\nจากเพื่อนและการแสดงบทบาทสมมุติ \nโดยใช้รูปแบบการตัดสินใจแบบ D.A.R.E. \nเพื่อโต้ตอบแรงกดดันจากเพื่อนประเภทต่าง ๆ"
//     },
//     {
//         lesson_number: "7",
//         lesson_name: "การรวมทุกสิ่งเข้าด้วยกัน",
//         content: "-  แนะนำบทเรียน\n-  การฝึกทักษะการปฏิเสธยาเสพติด \nหลีกเลี่ยงความรุนแรง / สถานการณ์เสี่ยง\n เพื่อดูแลรับผิดชอบชีวิตตนเอง โดยใช้รูปแบบ\nการโต้ตอบแบบมั่นใจ และแสดงความสามารถ\nในการประยุกต์ใช้ขั้นตอนการตัดสินใจได้"
//     },
//     {
//         lesson_number: "8",
//         lesson_name: "การกระทำของแต่ละคน",
//         content: "- แนะนำบทเรียน\n- การอธิบายถึงแรงกดดันจากภายในที่มีอิทธิพล\nต่อการตัดสินใจเกี่ยวกับการใช้ยาเสพติด \nทบทวนบทเรียนหลักสูตร D.A.R.E. \nเพื่อนำข้อมูลมาเขียนเรียงความ D.A.R.E."
//     },
//     {
//         lesson_number: "9",
//         lesson_name: "ฝึก ฝึก และฝึก",
//         content: "- แนะนำบทเรียน\n- เป็นการประยุกต์ใช้รูปแบบการตัดสินใจแบบ\n D.A.R.E. วิธีการดูแลรับผิดชอบตนเอง \nและการโต้ตอบอย่างมั่นใจในสถานการณ์\nเสี่ยงสำหรับการใช้ชีวิตประจำวันของเด็กนักเรียน"
//     },
//     {
//         lesson_number: "10",
//         lesson_name: "การป้องกันอาชญากรรมด้วยตนเอง",
//         content: "- แนะนำบทเรียน\n- นักเรียนสามารถหลีกเลี่ยงภัยจาก\nการก่ออาชญากรรม สาเหตุของการเกิด\nอาชญากรรม รู้วิธีหลีกเลี่ยงอาชญากรรม\n สถานการณ์การก่ออาชญากรรมในปัจจุบัน"
//     },
//     {
//         lesson_number: "11",
//         lesson_name: "ความรู้เกี่ยวกับการจราจร",
//         content: "- แนะนำบทเรียน\n- การใช้รถใช้ถนนอย่างปลอดภัยใน \n- รู้กฎหมายจราจรที่เกี่ยวข้อง รู้เรื่องเกี่ยวกับ\nเครื่องหมายจราจรที่สำคัญ รู้มาตรการ\nการขับขี่อย่างปลอดภัย การแก้ปัญหาเบื้องต้น\nเมื่อประสบอุบัติเหตุ"
//     },
//     {
//         lesson_number: "12",
//         lesson_name: "โรงพักของเรา",
//         content: "- เด็กนักเรียนได้เรียนรู้ระบบการทำงานของตำรวจ\nวิธีติดต่องานกับเจ้าหน้าที่ตำรวจรวมถึงระบบงาน\nยุติธรรมเบื้องต้น"
//     },
//     {
//         lesson_number: "13",
//         lesson_name: "กิจกรรมพิเศษ",
//         content: "- การจัดกิจกรรมวันปิดโครงการ D.A.R.E. \nสร้างความภาคภูมิใจให้กับนักเรียน D.A.R.E.\nโดยเชิญผู้ที่เกี่ยวข้องกับโครงการฯมาเป็นเกียรติ\nเช่น ผู้บริหารโรงเรียน คุณครูประจำชั้น ผู้ปกครอง\nผู้บังคับบัญชาของครูตำรวจ D.A.R.E.และหน่วย\nงานที่สนับสนุน โดยมีการอ่านคำปฏิญาณของ\nนักเรียน D.A.R.E.การแสดงของนักเรียนและ\nการมอบประกาศนียบัตรให้แก่นักเรียน D.A.R.E."
//     },
// ];


function formatThai(date = "") {
    const tmpDate = new Date(date)

    let monthNames = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.",
        "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.",
        "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
    ];

    let day = tmpDate.getDate();

    let monthIndex = tmpDate.getMonth();
    let monthName = monthNames[monthIndex];

    let year = tmpDate.getFullYear() + 543;

    return `${day} ${monthName} ${year}`;
    // return tmpDate.toLocaleDateString('th-TH', {
    //     year: 'numeric',
    //     month: 'long',
    //     day: 'numeric',
    // })
}

// function formatCurrencyThai(number = 0) {
//     return new Intl.NumberFormat('th-TH').format(number);
// }

// function formatCurrencyNumberToText(number = 0) {
//     let str = number.toString().split('')
//     let wording = "";
//     if (str && str.length == 6) {
//         wording = `${numToWord[str[0]]}แสน${str[1] == '0' ? '' : numToWord[str[1]] + 'หมื่น'}${str[2] == '0' ? '' : numToWord[str[2]] + 'พัน'}${str[3] == '0' ? '' : numToWord[str[3]] + 'ร้อย'}บาทถ้วน`
//     } else if (str && str.length == 5) {
//         wording = `${numToWord[str[0]]}หมื่น${str[1] == '0' ? '' : numToWord[str[1]] + 'พัน'}${str[2] == '0' ? '' : numToWord[str[2]] + 'ร้อย'}บาทถ้วน`
//     } else if (str && str.length == 4) {
//         wording = `${numToWord[str[0]]}พัน${str[1] == '0' ? '' : numToWord[str[1]] + 'ร้อย'}บาทถ้วน`
//     } else if (str && str.length == 3) {
//         wording = `${numToWord[str[0]]}ร้อยบาทถ้วน`
//     }

//     return wording
// }

export async function generateQrCodePayment(item = {}) {
    const { title, srcImgBase64 } = item

    return `
    <!DOCTYPE html>
    <html>
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title></title>
    </head>
    <body style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">
    <p style='text-align: center; margin:0in;font-size:22px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 22px;"><strong><span style='font-family:"TH Niramit AS";'>${title ? title : ''}</span></strong></span></p>
    <br />
    <p class="pb-0 mb-0" style='text-align: center; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>ระบบจะทำการส่ง SMS ยืนยันการอนุมัติการสมัครสมาชิกชมรมแดร์ประเทศไทยให้ท่านอีกครั้ง</span></p>
    <p style='text-align: center; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>เพื่อรับหมายเลขการสมัครสมาชิกเพื่อใช้ในการอัพโหลดหลักฐานการชำระเงิน</span></p>
    <br />
    <p class="pb-0 mb-0" style='text-align: center; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>ท่านสามารถดาวน์โหลด QR Code ข้างล่างเพื่อใช้ในการสแกนตรวจสอบ</span></p>
    <p style='text-align: center; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>รายละเอียดการสมัครสมาชิกได้ในภายหลัง</span></p>
    <br />
    <p style='text-align: center; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>ขอบคุณสำหรับการสมัครสมาชิกเพื่อเป็นส่วนหนึ่งของชมรมแดร์ประเทศไทย</span></p>
    <br />
    <p class="py-3" style="text-align: center;"><img src=${srcImgBase64 ? srcImgBase64 : ''} style="width: 300px; height: 300px;"/></p>
    </body>
    
    </html>
    `
}

export async function generateQrCode(item = {}, teachingDare = {}) {
    const { surveyTitle, srcImgBase64 } = item
    const {
        m_school = {},
            m_class = {},
            room = '',
            actual_student = '',
            term = '',
            fiscal_year = '',
            director_dares = [],
            teacher_dares = [],
    } = teachingDare;

    let form_teacher_dare = ""
    if (teacher_dares && teacher_dares.length > 0) {
        for (let index = 0; index < teacher_dares.length; index++) {
            const teacher_dare = teacher_dares[index];
            form_teacher_dare += `<p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ${teacher_dare.m_prefix ? teacher_dare.m_prefix.m_prefix_name : ''} ${teacher_dare.first_name ? teacher_dare.first_name : ''} ${teacher_dare.last_name ? teacher_dare.last_name : ''}</span></p>`
        }
    } else {
        form_teacher_dare += `<p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ............................................... </span></p>`
    }

    let info_teaching_dare = `
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ประจำภาคเรียนที่: ${term ? term : '.....................'}/${fiscal_year ? fiscal_year : '.....................'}</span></p>
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>โรงเรียน: ${m_school && m_school.m_school_name ? m_school.m_school_name : '.....................'}</span></p>
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ห้องเรียน: ${m_class && m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}</span></p>
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>จำนวนนักเรียน: ${actual_student ? actual_student : '.....................'} คน</span></p>
                                ${form_teacher_dare}
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ผู้อำนวยการโรงเรียน / ผู้บริหารโรงเรียน: ${director_dares && director_dares[0] ? `${director_dares[0].m_prefix ? director_dares[0].m_prefix.m_prefix_name : ''} ${director_dares[0].first_name} ${director_dares[0].last_name}` : '...............................................'}</span></p>
                            `


    return `
    <!DOCTYPE html>
    <html>
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title></title>
    </head>
    <body style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">
    <p style='margin:0in;font-size:22px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 22px;"><strong><span style='font-family:"TH Niramit AS";'>${surveyTitle ? surveyTitle : ''}</span></strong></span></p>
    <br />
    ${info_teaching_dare}
    <p class="py-3" style="text-align: center;"><img src=${srcImgBase64 ? srcImgBase64 : ''} style="width: 300px; height: 300px;"/></p>
    </body>
    
    </html>
    `
}

export async function generateQrCodeAll(item = [], teachingDare = {}) {
    let form_teacher_dare = ""
    let form_qrcode = ""
    const {
        m_school = {},
        m_class = {},
        room = '',
        actual_student = '',
        term = '',
        fiscal_year = '',
        director_dares = [],
        teacher_dares = [],
    } = teachingDare;

    if (teacher_dares && teacher_dares.length > 0) {
        for (let index = 0; index < teacher_dares.length; index++) {
            const teacher_dare = teacher_dares[index];
            form_teacher_dare += `<p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ${teacher_dare.m_prefix ? teacher_dare.m_prefix.m_prefix_name : ''} ${teacher_dare.first_name ? teacher_dare.first_name : ''} ${teacher_dare.last_name ? teacher_dare.last_name : ''}</span></p>`
        }
    } else {
        form_teacher_dare += `<p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ............................................... </span></p>`
    }

    let info_teaching_dare = `
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ประจำภาคเรียนที่: ${term ? term : '.....................'}/${fiscal_year ? fiscal_year : '.....................'}</span></p>
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>โรงเรียน: ${m_school && m_school.m_school_name ? m_school.m_school_name : '.....................'}</span></p>
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ห้องเรียน: ${m_class && m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}</span></p>
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>จำนวนนักเรียน: ${actual_student ? actual_student : '.....................'} คน</span></p>
                                ${form_teacher_dare}
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ผู้อำนวยการโรงเรียน / ผู้บริหารโรงเรียน: ${director_dares && director_dares[0] ? `${director_dares[0].m_prefix ? director_dares[0].m_prefix.m_prefix_name : ''} ${director_dares[0].first_name} ${director_dares[0].last_name}` : '...............................................'}</span></p>
                            `

    for (let index = 0; index < item.length; index++) {
        const element = item[index];
        const { survey, dataUrl } = element
        form_qrcode += `
        <p style='margin:0in;font-size:22px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 22px;"><strong><span style='font-family:"TH Niramit AS";'>${survey && survey.survey_title ? survey.survey_title : ''}</span></strong></span></p>
        <br />
        ${info_teaching_dare}
        <p class="py-3" style="text-align: center;"><img src=${dataUrl ? dataUrl : ''} style="width: 300px; height: 300px;"/></p>
        <div class="html2pdf__page-break"></div>
        `
    }



    return `
    <!DOCTYPE html>
    <html>

    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title></title>
    </head>

    <body style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">
    ${form_qrcode}
    </body>

    </html>
    `
}

export async function generateTemplate(item = {}) {
    let f_html = ""
    let f_html_student_list = ""
    if (item && item.teaching_dares && item.teaching_dares.length > 0) {
        for (let index = 0; index < item.teaching_dares.length; index++) {
            const {
                m_school = {},
                m_class = {},
                room = '',
                actual_student = '',
                teaching_plans = [],
                term = '',
                fiscal_year = '',
                director_dares = [],
                mapping_teaching_students = [],
                teacher_dares = [],
                user_profile = {}
            } = item.teaching_dares[index];

            // console.log('teaching_dares', item.teaching_dares[index]);

            // console.log(
            //     m_class,
            //     actual_student,
            //     teaching_plans);

            let form_lessons_table = ""
            let form_teaching_plan = ""
            let form_teacher_dare = ""
            let form_student_lists = ""

            if (teacher_dares && teacher_dares.length > 0) {
                for (let index = 0; index < teacher_dares.length; index++) {
                    const teacher_dare = teacher_dares[index];
                    form_teacher_dare += `<p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ${teacher_dare.m_prefix ? teacher_dare.m_prefix.m_prefix_name : ''} ${teacher_dare.first_name ? teacher_dare.first_name : ''} ${teacher_dare.last_name ? teacher_dare.last_name : ''}</span></p>`
                }
            } else {
                form_teacher_dare += `<p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ............................................... </span></p>`
            }

            if (mapping_teaching_students && mapping_teaching_students.length > 0) {
                for (let index = 0; index < mapping_teaching_students.length; index++) {
                    const mapping_teaching_student = mapping_teaching_students[index];
                    const { student_dare } = mapping_teaching_student;
                    form_student_lists += `
                    <tr>
                        <td style="width: 10%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; vertical-align: top;">
                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>${index + 1}</span></p>
                        </td>
                        <td style="width: 45%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>  ${student_dare && student_dare.m_prefix ? student_dare.m_prefix.m_prefix_name : ''} ${student_dare ? student_dare.first_name : ''} ${student_dare ? student_dare.last_name : ''}</span></p>  
                        </td>
                        <td style="width: 45%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>${student_dare.id_card ? student_dare.id_card : ''}</span></p>        
                        </td>
                    </tr>
                    `
                }
            }


            let info_teaching_dare = `
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;margin-top:6.0pt;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ประจำภาคเรียนที่: ${term ? term : '.....................'}/${fiscal_year ? fiscal_year : '.....................'}</span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>โรงเรียน: ${m_school.m_school_name ? m_school.m_school_name : '.....................'} &nbsp;</span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ห้องเรียน: ${m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}</span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>จำนวนนักเรียน: ${actual_student ? actual_student : '.....................'} คน</span></p>
                ${form_teacher_dare}
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ผู้อำนวยการโรงเรียน / ผู้บริหารโรงเรียน: ${director_dares && director_dares[0] ? `${director_dares[0].m_prefix ? director_dares[0].m_prefix.m_prefix_name : ''} ${director_dares[0].first_name} ${director_dares[0].last_name}` : '...............................................'}</span></p>
            `

            let info_student_list = `
                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;margin-top:12.0pt;text-align:center;'><span style="font-size: 20px;"><strong><span style='font-family:"TH Niramit AS";'>รายชื่อนักเรียน ชั้น ${m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}</span></strong></span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>โรงเรียน: ${m_school.m_school_name ? m_school.m_school_name : '.....................'}</span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ครูประจำชั้น: ${user_profile.m_rank ? user_profile.m_rank.m_rank_name : ''} ${user_profile.first_name ? user_profile.first_name : ''} ${user_profile.last_name ? user_profile.last_name : ''}</span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>รหัสประจำตัวครูตำรวจ D.A.R.E:${user_profile.id_governmen ? user_profile.id_governmen : '.....................'}</span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>จำนวนนักเรียน: ${actual_student ? actual_student : '.....................'} คน</span></p>
            `
            let sort_teaching_plans = sortBy(teaching_plans, [function (o) { return parseInt(o.lesson_number); }])

            if (sort_teaching_plans && sort_teaching_plans.length > 0) {
                for (let index = 0; index < sort_teaching_plans.length; index++) {
                    const lesson = sort_teaching_plans[index];
                    let form_lesson_content = '';
                    let form_lesson_note = '';
                    let form_lesson_teaching_actual_imgs = `
                        <p class="pb-4" style='margin:0in;font-size:20px;font-family:"Angsana New",serif;margin-top:12.0pt;text-align:center;'><span style="font-size: 20px;"><strong><span style='font-family:"TH Niramit AS";'>ภาพกิจกรรม</span></strong></span></p>
                        <p class="pb-3" style="text-align: center;"><span style="font-size: 15px;"><strong><span style='font-family: "TH Niramit AS";'>ครั้งที่ &nbsp;${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; เรื่อง &nbsp; บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; ${lesson.lesson_name ? lesson.lesson_name : ''}</span></strong></span></p>
                        `;
                    let lesson_contents = LESSON_CONTENT_MAPPING[lesson.lesson_name] ? LESSON_CONTENT_MAPPING[lesson.lesson_name].split('\n') : '';
                    let teaching_actual_imgs = lesson.teaching_actual_imgs ? lesson.teaching_actual_imgs : null;
                    if (teaching_actual_imgs && teaching_actual_imgs.length > 0) {
                        for (let index = 0; index < teaching_actual_imgs.length; index++) {
                            const teaching_actual_img = teaching_actual_imgs[index];
                            const { teaching_act } = teaching_actual_img;
                            if (teaching_act) {
                                const toDataURL = (url) =>
                                    fetch(url)
                                        .then((response) => response.blob())
                                        .then(
                                            (blob) =>
                                                new Promise((resolve, reject) => {
                                                    const reader = new FileReader();
                                                    reader.onloadend = () => resolve(reader.result);
                                                    reader.onerror = reject;
                                                    reader.readAsDataURL(blob);
                                                })
                                        );
                                let imgBase64 = await toDataURL(teaching_act);
                                if (imgBase64) {
                                    form_lesson_teaching_actual_imgs += `<p class="py-3" style="text-align: center;"><img src=${imgBase64 ? imgBase64 : ''} style="width: 480px; height: 360px;"/></p>`
                                }
                            }
                            if (teaching_actual_img.teaching_actual_img) {
                                const toDataURL = (url) =>
                                    fetch(url)
                                        .then((response) => response.blob())
                                        .then(
                                            (blob) =>
                                                new Promise((resolve, reject) => {
                                                    const reader = new FileReader();
                                                    reader.onloadend = () => resolve(reader.result);
                                                    reader.onerror = reject;
                                                    reader.readAsDataURL(blob);
                                                })
                                        );
                                let imgBase64 = await toDataURL(teaching_actual_img.teaching_actual_img);
                                if (imgBase64) {
                                    form_lesson_teaching_actual_imgs += `<p class="py-4" style="text-align: center;"><img src=${imgBase64 ? imgBase64 : ''} style="width: 480px; height: 360px;"/></p>`
                                }
                            }

                            if (teaching_actual_imgs.length == 1) {
                                form_lesson_teaching_actual_imgs += `
                                    <p class="py-5" style="text-align: center; width: 480px; height: 400px;"></p>
                                `
                            }
                        }
                    } else {
                        form_lesson_teaching_actual_imgs += `
                            <p class="py-4" style="text-align: center; width: 480px; height: 400px;"></p> 
                            <p class="py-4" style="text-align: center; width: 480px; height: 400px;"></p> 
                        `
                    }


                    for (let index = 0; index < lesson_contents.length; index++) {
                        const lesson_content = lesson_contents[index];

                        const size = 47
                        if (lesson_content.length > size) {
                            const numChunks = Math.ceil(lesson_content.length / size)
                            let chunk = ''
                            for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
                                chunk = lesson_content.substr(o, size)

                                form_lesson_content += `<p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 14px;' >${chunk}</p>`
                            }
                        } else {
                            form_lesson_content += `<p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 14px;' >${lesson_content}</p>`
                        }
                    }

                    const lesson_note = lesson.note ? lesson.note.replace(/ /g, '') : '';
                    const size = 13;
                    if (lesson_note.length > size) {
                        const numChunks = Math.ceil(lesson_note.length / size)
                        let chunk = ''
                        for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
                            chunk = lesson_note.substr(o, size)

                            form_lesson_note += `<p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 15px;' >${chunk}</p>`
                        }
                    } else {
                        form_lesson_note += `<p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 15px;' >${lesson_note}</p>`
                    }


                    form_lessons_table += `
                        <tr>
                            <td style="width: 39.15pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>${lesson.lesson_number ? lesson.lesson_number : ''}</span></p>
                            </td>
                            <td style="width: 86.75pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>${lesson.date ? formatThai(lesson.date) : ''}</span></p>
                            </td>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>${lesson.time ? `${lesson.time} น.` : ''}</span></p>
                            </td>
                            <td style="width: 290.6pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp;${lesson.lesson_name ? lesson.lesson_name : ''}</span></p>
                            </td>
                        </tr>
                    `

                    form_teaching_plan += `
                        <div style='height: 900.24px' class="p-0 m-0" >
                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 20px;"><strong><span style='font-family:"TH Niramit AS";'>บันทึกการสอน</span></strong></span></p>
                            <p class="pb-4" style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 20px;"><strong><span style='font-family:"TH Niramit AS";'>โครงการการศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน (</span></strong><strong><span style='font-family:"TH Niramit AS";'>D.A.R.E.ประเทศไทย)</span></strong></span></p>
                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;margin-top:12.0pt;'><span style="font-size: 20px;"><strong><span style='font-family:"TH Niramit AS";'>ครั้งที่ &nbsp;${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; เรื่อง &nbsp; บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; ${lesson.lesson_name ? lesson.lesson_name : ''}</span></strong></p>
                            ${info_teaching_dare}
                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>&nbsp;</span></p>
                            <table style="width:480pt;border-collapse:collapse;border:none;">
                                <tbody>
                                    <tr>
                                        <td style="width: 15%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:22px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>วัน เดือน ปี</span></p>
                                        </td>
                                        <td style="width: 15%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:22px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>เวลา</span></p>
                                        </td>
                                        <td style="width: 53%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:22px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>เนื้อหา / และกิจกรรมที่ทำการสอน</span></p>
                                        </td>
                                        <td style="width: 17%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:22px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>หมายเหตุ</span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 15%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:22px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>${lesson.date ? formatThai(lesson.date) : ''}</span></p>
                                        </td>
                                        <td style="width: 15%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:22px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>${lesson.time ? `${lesson.time} น.` : ''}</span></p>
                                        </td>
                                        <td style="width: 53%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:22px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; ${lesson.lesson_name ? lesson.lesson_name : ''}</span></p>
                                            ${form_lesson_content}
                                        </td>
                                        <td style="width: 17%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                            ${form_lesson_note}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>&nbsp;</span></p>
                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ความเห็นอาจารย์ประจำชั้น หรือ อาจารย์ผู้ดูแลการเรียนการสอน</span></p>
                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;margin-top:6.0pt;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>...................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................</span></p>
                            <p style='margin: 12pt 0in 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ลงชื่อ........................................................................อาจารย์ผู้ดูแลการเรียนการสอน</span></p>
                            <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>&nbsp;</span></p>
                            <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ลงชื่อ..................................................................ผู้ดำเนินโครงการ</span></p>
                            <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>(.............................................)</span></p>
                            <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>.........................................................................</span></p>   
                        </div>
                        ${form_lesson_teaching_actual_imgs}
                        <br />
                    `
                }
            }

            f_html_student_list += `
            ${info_student_list}
            <br />
            <table style="width:480pt;border-collapse:collapse;border:none;">
                <tbody>
                <tr>
                    <td style="width: 10%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                        <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>ลำดับ</span></p>
                    </td>
                    <td style="width: 45%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                        <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>ชื่อ นามสกุล นักเรียน</span></p>
                    </td>
                    <td style="width: 45%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                        <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>เลขประจำตัวประชาชน</span></p>
                    </td>
                </tr>
                    ${form_student_lists}
                </tbody>
            </table>
            <br />
            <br />
            <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ตรวจแล้วถูกต้อง</span></p>
            <br />
            <br />
            <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ลงชื่อ..................................................................</span></p>
            <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>( ${user_profile.m_rank ? user_profile.m_rank.m_rank_name : ''} ${user_profile.first_name ? user_profile.first_name : '.........................'} ${user_profile.last_name ? user_profile.last_name : '.........................'} )</span></p>
            <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ตำแหน่ง ${user_profile.position_name ? user_profile.position_name : '.................................'}</span></p>
            `


            f_html += `
                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><strong><span style='font-size: 20px; font-family: "TH Niramit AS";'>แผนการสอน โครงการการศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน (</span></strong><span style="font-size: 20px;"><strong><span style='font-family: "TH Niramit AS";'>D.A.R.E.ประเทศไทย)</span></strong></span></p>
                <br />
                ${info_teaching_dare}
                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-size: 20px; font-family: "TH Niramit AS";'>&nbsp;</span></p>
                <table style="width:480pt;border-collapse:collapse;border:none;">
                    <tbody>
                        <tr>
                            <td style="width: 39.15pt;border: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 14px;"><strong><span style='font-family: "TH Niramit AS";'>ลำดับ</span></strong></span></p>
                            </td>
                            <td style="width: 86.75pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 14px;"><strong><span style='font-family:"TH Niramit AS";'>วัน เดือน ปี</span></strong></span></p>
                            </td>
                            <td style="width: 92.15pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 14px;"><strong><span style='font-family:"TH Niramit AS";'>เวลา</span></strong></span></p>
                            </td>
                            <td style="width: 290.6pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 14px;"><strong><span style='font-family:"TH Niramit AS";'>เนื้อหาที่ทำการสอน</span></strong></span></p>
                            </td>
                        </tr>
                        ${form_lessons_table}
                    </tbody>
                </table>
                <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: left;'><br></p>
                <p id="isPasted" style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ลงชื่อ...........................................................................ผู้ดำเนินโครงการ</span></p>
                <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>(..............................................)</span></p>
                <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>........................................................................................</span></p>
                <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>ลงชื่อ.................................................................ผู้บังคับบัญชาต้นสังกัด</span></p>
                <p style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>(............................................)</span></p>
                <p class="pb-0 mb-0" style='margin: 0in; font-size: 20px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 20px;'>........................................................................................</span></p>
                <br />
                <br />
                <br />
                <br />
                <br />
                ${form_teaching_plan}
                ${f_html_student_list}
                ${index > 0 && index < item.teaching_dares.length ? `<div class="html2pdf__page-break"></div>` : ''}
            `
        }
    }
    // let schools = "";
    // let lesson_plan_counts = 0;
    // let teaching_dare_table = "";
    // let total_time_lesson_plan = 0;
    // let total_price_lesson_plan = 0;
    // let pricePerHrs = 0
    // if (selected == "0") {
    //     pricePerHrs = 300;
    // }
    // if (selected == "1") {
    //     pricePerHrs = 600;
    // }
    // if (item && item.teaching_dares) {
    //     for (let index = 0; index < item.teaching_dares.length; index++) {
    //         const {
    //             m_school = {},
    //             m_class = {},
    //             actual_student,
    //             teaching_plans,
    //             term,
    //             fiscal_year
    //         } = item.teaching_dares[index];

    //         schools += `<p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-indent:.5in;'><span style='font-family:"TH Niramit AS";'>โรงเรียน ${m_school?.m_school_name ? m_school.m_school_name : ""
    //             } ชั้น ${m_class?.m_class_name ? m_class.m_class_name : "..."
    //             } </span></p>
    //               <p class="mb-3" style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-indent:.5in;'><span style='font-family:"TH Niramit AS";'>จำนวนนักเรียน ${actual_student ? actual_student : 0
    //             } คน</span></p>`;

    //         lesson_plan_counts += teaching_plans.length;

    //         let hours = 0;
    //         let mins = 0;

    //         if (teaching_plans) {
    //             for (let index = 0; index < teaching_plans.length; index++) {
    //                 const element = teaching_plans[index].totalTime;
    //                 if (element) {
    //                     const times = element.split(":");
    //                     hours += parseInt(times[0]);
    //                     mins += parseInt(times[1]);
    //                 }
    //             }
    //         }

    //         total_time_lesson_plan = hours + mins / 60;
    //         total_price_lesson_plan += total_time_lesson_plan * pricePerHrs

    //         teaching_dare_table += `
    //             <tr>
    //                 <td style="width: 83%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; vertical-align: bottom;">
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp;ค่าตอบแทนวิทยากรตามโครงการการศึกษาเพื่อต่อต้าน</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>การใช้ยาเสพติดในเด็กนักเรียน (โครงการ&nbsp;</span><span style='font-family:"TH Niramit AS";'>D.A.R.E. ประเทศไทย) ประจำภาคเรียนที่ ${term ? term : ''}/${fiscal_year ? fiscal_year : ''}</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>ณ โรงเรียน ${m_school?.m_school_name ? m_school.m_school_name : ""}</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>ตำบล .............................&nbspอำเภอ ${m_school?.m_amphur?.m_amphur_name ? m_school.m_amphur.m_amphur_name : ""}</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>จังหวัด ${m_school?.m_province?.m_province_name ? m_school.m_province.m_province_name : ""}</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>ดำเนินการระหว่างวันที่ ${teaching_plans[0] ? formatThai(teaching_plans[0].date) : ""}</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>ถึงวันที่ ${teaching_plans[teaching_plans.length - 1] ? formatThai(teaching_plans[teaching_plans.length - 1].date) : ""}</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>จำนวน ${parseInt(total_time_lesson_plan)} ชั่วโมง ๆ ละ ${pricePerHrs} บาท ต่อห้อง รวม 1 ห้องเรียน&nbsp;</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>เป็นเงิน</span></p>
    //                 </td>

    //                 <td style="width: 17%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: bottom;">
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>${formatCurrencyThai(parseInt(total_time_lesson_plan) * pricePerHrs)}</span><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //                 </td>
    //             </tr>
    //         `
    //     }
    // }

    return `<!DOCTYPE html>
    <html>

    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title></title>
    </head>

    <body style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">
        ${f_html}
    </body>

    </html>`

    // return `
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-size:24px;font-family:"TH Niramit AS";'>เอกสารประกอบการรายงานเพื่อขอเบิกค่าตอบแทนการสอน</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-family:"TH Niramit AS";'>โครงการการศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน (&nbsp;</span></strong><strong><span style='font-family:"TH Niramit AS";'>D.A.R.E.ประเทศไทย )</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-family:"TH Niramit AS";'>( ภาคเรียนที่ .........................)</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>&nbsp;</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>ชื่อครูตำรวจ&nbsp;</span></strong><strong><span style='font-family:"TH Niramit AS";'>D.A.R.E.&nbsp;</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ชื่อ ${user?.first_name ? user.first_name : ""} ${user?.last_name ? user.last_name : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ชั้นยศ ${user?.m_rank?.m_rank_name ? user.m_rank.m_rank_name : "..."} </span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; รหัสครูตำรวจ D.A.R.E&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;..</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>ขอเบิกค่าตอบแทนการสอนจำนวน ${item?.teaching_dares ? item?.teaching_dares.length : 0} ห้องเรียน ดังนี้</span></strong></p>
    //     ${schools}
    //     <br>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>เอกสารประกอบการเบิกค่าตอบแทนการสอน</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>1. ใบสำคัญรับเงิน&nbsp;</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>2. แผนการสอน ${lesson_plan_counts} &nbsp;บทเรียน (${item?.teaching_dares ? item?.teaching_dares.length : 0} ห้องเรียน)&nbsp;</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>3. บันทึกการสอน ${lesson_plan_counts} บทเรียน &nbsp;พร้อมภาพกิจกรรมแต่ละบทเรียน (${item?.teaching_dares ? item?.teaching_dares.length : 0} ห้องเรียน)&nbsp;</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>4. รายชื่อนักเรียนที่สอน (พร้อมเลข 13 หลัก และรหัสนักเรียน&nbsp;D.A.R.E.)</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>5. สำเนาบัตรประจำตัวประชาชน</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>6. สำเนาหน้าสมุดบัญชีครูตำรวจ&nbsp;D.A.R.E. (ธนาคารกรุงไทย) (${item?.teaching_dares ? item?.teaching_dares.length : 0} ห้องเรียน)</span></p>
    //     <p><span style='page-break-after: always;font-size:21px;font-family:"TH Niramit AS";'>7. แบบรายงานการบันทึกการสอนในระบบฐานข้อมูล&nbsp;D.A.R.E.</span></p>

    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-size:24px;font-family:"TH Niramit AS";'>ใบสำคัญรับเงิน</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>วันที่...............เดือน.............พ.ศ..............</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><br></p>
    //     <p id="isPasted" style='margin:0in;font-size:21px;font-family:"Sarabun",serif;margin-left:.5in;text-indent:.5in;'><span style='font-family:"TH Niramit AS";'>ข้าพเจ้า...............................................ชั้นยศ..........................................</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-size:21px;font-family:"TH Niramit AS";'>อยู่บ้านเลขที่..............หมู่ที่..................ถนน..........................ตำบล..............................</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-size:21px;font-family:"TH Niramit AS";'>อำเภอ....................................................จังหวัด...........................................................</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-size:21px;font-family:"TH Niramit AS";'>ได้รับเงินจาก............................ตำรวจภูธรจังหวัด..............................................</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-size:21px;font-family:"TH Niramit AS";'>ดังรายการต่อไปนี้</span></p>

    //     <p><br></p>
    //     <table style="border-collapse:collapse;border:none;">
    //         <tbody>
    //             <tr>
    //                 <td style="width: 83%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-family:"TH Niramit AS";'>รายการ</span></strong></p>
    //                 </td>
    //                 <td style="width: 17%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>จำนวนเงิน</span></strong></p>
    //                 </td>
    //             </tr>

    //                 ${teaching_dare_table}

    //             <tr>
    //                 <td style="border: none; padding: 0in; width: 83%;">
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'>&nbsp;</p>
    //                 </td>
    //                 <td style="width: 17%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; height: 20.05pt; vertical-align: top;">
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>${formatCurrencyThai(total_price_lesson_plan)}</span></p>
    //                 </td>
    //             </tr>
    //         </tbody>
    //     </table>

    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  จำนวนเงิน &nbsp; (${formatCurrencyNumberToText(total_price_lesson_plan)})</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //     <p style='margin: 0in; font-size: 21px; font-family: "Sarabun", serif; text-align: right;'><span style='font-family:"TH Niramit AS";'>&nbsp; ลงชื่อ..................</span><span style='font-family:"TH Niramit AS";'>&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;.ผู้รับเงิน</span></p>
    //     <p id="isPasted" style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;( .................................................)</span></p>
    //     <p id="isPasted" style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp;ชั้นยศ .........................................</span></p>
    //     <p style='margin: 0in; font-size: 21px; font-family: "Sarabun", serif; text-align: right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span><span style='font-family:"TH Niramit AS";'>ลงชื่อ<span id="isPasted" style='color: rgb(0, 0, 0); font-size: 21px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: right; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; font-family: "TH Niramit AS";'>..................</span><span style='color: rgb(0, 0, 0); font-size: 21px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: right; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; font-family: "TH Niramit AS";'>&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;...</span></span><span style='font-family:"TH Niramit AS";'>ผู้จ่ายเงิน</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;( .................................................)</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp;ชั้นยศ .........................................</span></p>
    // `;

}

/**
 * 
 * @notice Transaction
 */
export async function generateTemplate_v2(item = {}) {
    let f_html = ""
    let f_html_teaching_actual_imgs = ""
    let f_html_student_list = ""
    if (item && item.teaching_dares && item.teaching_dares.length > 0) {
        for (let index = 0; index < item.teaching_dares.length; index++) {
            const {
                m_school = {},
                m_class = {},
                room = '',
                actual_student = '',
                teaching_plans = [],
                term = '',
                fiscal_year = '',
                director_dares = [],
                mapping_teaching_students = [],
                teacher_dares = [],
                teaching_actual_imgs = [],
                user_profile = {}
            } = item.teaching_dares[index];

            let form_lessons_table = ""
            let form_teaching_plan = ""
            let form_teacher_dare = ""
            let signature_teacher_dare = ""
            let form_student_lists = `
            <table style="width:600px;border-collapse:collapse;border:none;">
                <tbody>
                    <tr>
                        <td style="width: 10%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                            <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                    ลำดับ
                                </b>
                            </p>
                        </td>
                        
                        <td style="width: 60%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                            <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                    ชื่อ นามสกุล นักเรียน
                                </b>
                            </p>
                        </td>

                        <td style="width: 30%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                            <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                    เลขประจำตัวประชาชน
                                </b>
                            </p>
                        </td>
                    </tr>
            `

            if (teacher_dares && teacher_dares.length > 0) {
                for (let index = 0; index < teacher_dares.length; index++) {
                    const teacher_dare = teacher_dares[index];
                    form_teacher_dare += `
                        <p style='font-weight: 700; margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                            <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                                อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ${teacher_dare.m_prefix ? teacher_dare.m_prefix.m_prefix_name : ''} ${teacher_dare.first_name ? teacher_dare.first_name : ''} ${teacher_dare.last_name ? teacher_dare.last_name : ''}
                            </span>
                        </p>
                        `

                    signature_teacher_dare += `
                        <p class="pb-0 mb-0" style='margin: 0in; font-size: 14px; font-weight: 700; font-family: "TH Niramit AS"; text-align: center;'>
                            ลงชื่อ
                            <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>
                                ...........................................................................
                            </span>
                            อาจารย์ประจำชั้น
                        </p>
                        <p class="pb-3 mb-0" style='margin: 0in; font-size: 14px; font-weight: 700; font-family: "Angsana New", serif; text-align: center;'>
                            <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                                (${teacher_dare ? `${teacher_dare.m_prefix ? teacher_dare.m_prefix.m_prefix_name : ''} ${teacher_dare.first_name ? teacher_dare.first_name : ''} ${teacher_dare.last_name ? teacher_dare.last_name : ''}` : `.....................................`})
                            </span>
                        </p>
                        `

                }
            } else {
                form_teacher_dare += `
                    <p style='font-weight: 700; margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                        <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                            อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: .................................................
                        </span>
                    </p>`
            }

            if (mapping_teaching_students && mapping_teaching_students.length > 0) {
                for (let index = 0; index < mapping_teaching_students.length; index++) {
                    const mapping_teaching_student = mapping_teaching_students[index];
                    const { student_dare } = mapping_teaching_student;

                    let maxStudentLength = 22;
                    let diffTeacherLength = teacher_dares && teacher_dares.length > 0 ? teacher_dares.length - 1 : 0;

                    if ((index + 1) % (maxStudentLength - diffTeacherLength) == 0 && (index + 1) <= (maxStudentLength - diffTeacherLength)) {
                        form_student_lists += `
                        </tbody>
                        </table>

                        <br />
                        <br />
                        <table style="width:600px;border-collapse:collapse;border:none;">
                        <tbody>
                            <tr>
                                <td style="width: 10%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                        <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                            ลำดับ
                                        </b>
                                    </p>
                                </td>
                                <td style="width: 60%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                        <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                            ชื่อ นามสกุล นักเรียน
                                        </b>
                                    </p>
                                </td>
                                <td style="width: 30%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                        <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                            เลขประจำตัวประชาชน
                                        </b>
                                    </p>
                                </td>
                            </tr>
                        `
                    } else if ((index + 1) % 45 == 0) {
                        form_student_lists += `
                        </tbody>
                        </table>

                        <br />
                        <table style="width:600px;border-collapse:collapse;border:none;">
                        <tbody>
                            <tr>
                                <td style="width: 10%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                        <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                            ลำดับ
                                        </b>
                                    </p>
                                </td>
                                <td style="width: 60%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                        <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                            ชื่อ นามสกุล นักเรียน
                                        </b>
                                    </p>
                                </td>
                                <td style="width: 30%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                        <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                            เลขประจำตัวประชาชน
                                        </b>
                                    </p>
                                </td>
                            </tr>
                        `
                    }

                    form_student_lists += `
                    <tr>
                        <td class="py-1" style="width: 10%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; vertical-align: top;">
                            <p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                <span style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                    ${index + 1}
                                </span>
                            </p>
                        </td>
                        <td class="py-1" style="width: 60%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                            <p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                                <span style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                    ${student_dare && student_dare.m_prefix ? student_dare.m_prefix.m_prefix_name : ''} ${student_dare ? student_dare.first_name : ''} ${student_dare ? student_dare.last_name : ''}
                                </span>
                            </p>  
                        </td>
                        <td class="py-1" style="width: 30%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                            <p style='text-align:center; margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                                <span style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                    ${student_dare.id_card ? student_dare.id_card : ''}
                                </span>
                            </p>        
                        </td>
                    </tr>
                    `
                }

                form_student_lists += `
                    </tbody>
                    </table>
                `
            }


            let info_teaching_dare = `
                <p style='font-weight: 700; margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                    <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                        ประจำภาคเรียนที่: ${term ? term : '.....................'}/${fiscal_year ? fiscal_year : '.....................'}
                    </span>
                </p>
                <p style='font-weight: 700; margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                    <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                        โรงเรียน: ${m_school && m_school.m_school_name ? m_school.m_school_name : '.....................'}
                    </span>
                </p>
                <p style='font-weight: 700; margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                    <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                        ห้องเรียน: ${m_class && m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}
                    </span>
                </p>
                <p style='font-weight: 700; margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                    <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                        จำนวนนักเรียน: ${actual_student ? actual_student : '.....................'} คน
                    </span>
                </p>
                ${form_teacher_dare}
                <p style='font-weight: 700; margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                    <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                        ผู้อำนวยการโรงเรียน / ผู้บริหารโรงเรียน: ${director_dares && director_dares[0] ? `${director_dares[0].m_prefix ? director_dares[0].m_prefix.m_prefix_name : director_dares[0].prefix_name ? director_dares[0].prefix_name : ''} ${director_dares[0].first_name} ${director_dares[0].last_name}` : '.................................................'}
                    </span>
                </p>
            `

            // let info_student_list = `
            //     <p style='font-weight: 700; margin:0in;font-size:20px;font-family:"Angsana New",serif;margin-top:12.0pt;text-align:center;'><span style="font-size: 20px;"><strong><span style='font-family:"TH Niramit AS";'>รายชื่อนักเรียน ชั้น ${m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}</span></strong></span></p>
            //     <br />
            //     <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>โรงเรียน: ${m_school && m_school.m_school_name ? m_school.m_school_name : '.....................'}</span></p>
            //     <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ครูประจำชั้น: ${user_profile && user_profile.m_rank ? user_profile.m_rank.m_rank_name : ''} ${user_profile.first_name ? user_profile.first_name : ''} ${user_profile.last_name ? user_profile.last_name : ''}</span></p>
            //     <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>รหัสประจำตัวครูตำรวจ D.A.R.E: ${user_profile && user_profile.id_governmen ? user_profile.id_governmen : '.....................'}</span></p>
            //     <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700;font-family: "TH Niramit AS"; font-size: 16px;'>จำนวนนักเรียน: ${actual_student ? actual_student : '.....................'} คน</span></p>
            // `
            let sort_teaching_plans = sortBy(teaching_plans, [function (o) { return parseInt(o.lesson_number); }])

            if (sort_teaching_plans && sort_teaching_plans.length > 0) {
                for (let plan = 0; plan < sort_teaching_plans.length; plan++) {
                    const lesson = sort_teaching_plans[plan];
                    let form_lesson_content = '';
                    let form_lesson_note = '';

                    let lesson_contents = LESSON_CONTENT_MAPPING[lesson.lesson_name] ? LESSON_CONTENT_MAPPING[lesson.lesson_name].split('\n') : '';

                    for (let index = 0; index < lesson_contents.length; index++) {
                        const lesson_content = lesson_contents[index];

                        form_lesson_content += `
                            <p style='margin:0in;font-size:${(plan == 9 || plan == 11 || plan == 12) ? `12px` : `14px`};font-family:"Angsana New",serif;'>
                                <span style='font-family: "TH Niramit AS"; font-size: ${(plan == 9 || plan == 11 || plan == 12) ? `12px` : `14px`}'>
                                    ${lesson_content}
                                </span>
                            </p>
                            `
                    }

                    const lesson_note = lesson.note ? lesson.note.replace(/ /g, '') : '';
                    const size = 11;
                    if (lesson_note.length > size) {
                        const numChunks = Math.ceil(lesson_note.length / size)
                        let chunk = ''
                        for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
                            chunk = lesson_note.substr(o, size)

                            form_lesson_note += `
                                <p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                                    <span style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                        ${chunk}
                                    </span>
                                </p>
                                `
                        }
                    } else {
                        form_lesson_note += `
                            <p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                                <span style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                    ${lesson_note}
                                </span>
                            </p>`
                    }


                    form_lessons_table += `
                        <tr>
                            <td style="width: 4%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                    <span style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                        ${lesson.lesson_number ? lesson.lesson_number : ''}
                                    </span>
                                </p>
                            </td>
                            <td style="width: 18%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                    <span style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                        ${lesson.date ? formatThai(lesson.date) : ''}
                                    </span>
                                </p>
                            </td>
                            <td style="width: 20%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                    <span style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                        ${lesson.time ? `${lesson.time} น.` : ''}
                                    </span>
                                </p>
                            </td>
                            <td style="width: 58%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size: 14px;font-family:"Angsana New",serif;'>
                                    <span style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                        บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''}&nbsp;${LESSON_NAME_MAPPING[lesson.lesson_name] ? LESSON_NAME_MAPPING[lesson.lesson_name] : ''}
                                    </span>
                                </p>
                            </td>
                        </tr>
                    `

                    let lesson_name_title = ``
                    let lengthForLessonName_2 = 95;
                    let tmpLessonName_2 = `ครั้งที่&nbsp;${lesson.lesson_number ? lesson.lesson_number : ''}&nbsp; เรื่อง&nbsp;บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''}&nbsp;${lesson.lesson_name ? lesson.lesson_name : ''}`
                    if (tmpLessonName_2.length > lengthForLessonName_2) {
                        const numChunks = Math.ceil(tmpLessonName_2.length / lengthForLessonName_2)
                        let chunk = ''
                        for (let i = 0, o = 0; i < numChunks; ++i, o += lengthForLessonName_2) {
                            chunk = tmpLessonName_2.substr(o, lengthForLessonName_2)
                            lesson_name_title += `
                            <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;margin-top:1.0pt;margin-bottom:0.5rem;'>
                                <strong>
                                    <span style='font-family:"TH Niramit AS";font-size: 16px;'>
                                        ${chunk}
                                    </span>
                                </strong>
                            </p>
                            `
                        }
                    } else {
                        lesson_name_title += `
                            <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;margin-top:1.0pt;margin-bottom:0.5rem;'>
                                <strong>
                                    <span style='font-family:"TH Niramit AS";font-size: 16px;'>
                                        ${tmpLessonName_2}
                                    </span>
                                </strong>
                            </p>
                            `
                    }

                    form_teaching_plan += `
                    <div style='max-height: 900.24px; height: 900.24px; width: 604px;' class="p-0 m-0">
                    <br />
                        <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'>
                            <strong>
                                <span style='font-family:"TH Niramit AS";font-size: 20px;'>
                                    บันทึกการสอน
                                </span>
                            </strong>
                        </p>
                        <p class="pb-4" style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'>
                            <strong>
                                <span style='font-family:"TH Niramit AS";font-size: 20px;'>
                                    โครงการการศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน
                                </span>
                                <span style='font-family:"TH Niramit AS";font-size: 20px;'>
                                    (D.A.R.E.ประเทศไทย)
                                </span>
                            </strong>
                        </p>
                        ${lesson_name_title}
                        ${info_teaching_dare}
                        <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;'>
                            <span style='font-family: "TH Niramit AS"; font-size: 16px;'>
                                &nbsp;
                            </span>
                        </p>
                        <table style="width:600px;border-collapse:collapse;border:none;margin-top:0.5rem;">
                            <tbody>
                                <tr>
                                    <td style="width: 14%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'>
                                            <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                                วัน เดือน ปี
                                            </b>
                                        </p>
                                    </td>
                                    <td style="width: 16%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'>
                                            <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                                เวลา
                                            </b>
                                        </p>
                                    </td>
                                    <td style="width: 46%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'>
                                            <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                                เนื้อหา / และกิจกรรมที่ทำการสอน
                                            </b>
                                        </p>
                                    </td>
                                    <td style="width: 24%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'>
                                            <b style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                                หมายเหตุ
                                            </b>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 14%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                            <span style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                                ${lesson.date ? formatThai(lesson.date) : ''}
                                            </span>
                                        </p>
                                    </td>
                                    <td style="width: 16%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                                            <span style='font-family: "TH Niramit AS"; font-size: 14px;'>
                                                ${lesson.time ? `${lesson.time} น.` : ''}
                                            </span>
                                        </p>
                                    </td>
                                    <td style="width: 46%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                                            <span style='font-family: "TH Niramit AS"; font-size: ${(lesson.lesson_number == 10 || lesson.lesson_number == 12 || lesson.lesson_number == 13) ? `12px` : `14px`}'>
                                                บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''}&nbsp;${LESSON_NAME_MAPPING[lesson.lesson_name] ? LESSON_NAME_MAPPING[lesson.lesson_name] : ''}
                                            </span>
                                        </p>
                                        ${form_lesson_content}
                                    </td>
                                    <td style="width: 24%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                        ${form_lesson_note}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;'>
                            <span style='font-family: "TH Niramit AS"; font-size: 16px;'>
                                &nbsp;
                            </span>
                        </p>
                        <p style='font-weight: 700; margin:0in;font-size:14px;font-family:"Angsana New",serif;'>
                            <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                                ความเห็นอาจารย์ประจำชั้น หรือ อาจารย์ผู้ดูแลการเรียนการสอน
                            </span>
                        </p>
                        <p style='font-weight: 700; margin:0in;font-size:14px;font-family:"Angsana New",serif;margin-top:6.0pt;'>
                            <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>
                                ...................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................
                            </span>
                        </p>
                        <p style='margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'>
                            <span style='font-family: "TH Niramit AS"; font-size: 16px;'>
                                &nbsp;
                            </span>
                        </p>
                        ${signature_teacher_dare}
                        <p class="pb-0 mb-0" style='font-weight: 700; margin: 0in; font-size: 14px; font-family: "TH Niramit AS"; text-align: center;'>
                            ลงชื่อ
                            <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>
                                ...........................................................................
                            </span>
                            ผู้ดำเนินโครงการ
                        </p>
                        <p class="pb-0 mb-0" style='font-weight: 700; margin: 0in; font-size: 14px; font-family: "Angsana New", serif; text-align: center;'>
                            <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                                (${user_profile ? `${user_profile && user_profile.m_rank ? user_profile.m_rank.m_rank_name : ''} ${user_profile.first_name ? user_profile.first_name : ''} ${user_profile.last_name ? user_profile.last_name : ''}` : `.....................................`})
                            </span>
                        </p>
                        <p style='font-weight: 700; margin: 0in; font-size: 14px; font-family: "Angsana New", serif; text-align: center;'>
                            <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                                ตำแหน่ง ${user_profile.position_name ? user_profile.position_name : '.................................'}
                            </span>
                        </p>
                    </div>
                    `
                }
            }

            const getImgUrl = () => {
                if (teaching_actual_imgs && teaching_actual_imgs.length > 0) {
                    const imgUrlPromise = Promise.all(
                        teaching_actual_imgs.map(async (imgKey) => {
                            let imgPromise = await TeachingDare.api().getImgBinary({
                                key: imgKey,
                            });
                            
                            if (imgPromise && imgPromise.response) {
                                const { hex } = imgPromise.response.data;
                                
                                if (hex) {
                                    return hex
                                }
                            }
                        })
                    );
                    return imgUrlPromise
                }
            };
            let form_teaching_actual_imgs = await getImgUrl();

            const hexToBase64 = (str) => {
                // return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
                return btoa(str.match(/\w{2}/g).map(function(a) {
                    return String.fromCharCode(parseInt(a, 16));
                }).join(""));
            }

            if (teaching_actual_imgs?.length > 0) {
                f_html_teaching_actual_imgs += `
                <div style='max-height: 900.24px; height: 900.24px; width: 604px;' class="p-0 m-0">
                    <p class="pb-4" style='margin:0in;font-size:20px;font-family:"Angsana New",serif;margin-top:12.0pt;text-align:center;'>
                        <strong>
                            <span style='font-family:"TH Niramit AS";font-size: 20px;'>
                                ภาพกิจกรรม
                            </span>
                        </strong>
                    </p>
                    <p class="pb-3" style="text-align: center;">
                        <strong>
                            <span style='font-family: "TH Niramit AS";font-size: 16px;'>
                                ครั้งที่ 1-13 บทที่ 1-13
                            </span>
                        </strong>
                    </p>

                    ${form_teaching_actual_imgs.length > 0 &&
                        form_teaching_actual_imgs.map((imgBinary) => {
                        const imgBase = "data:image/jpeg;base64," + hexToBase64(imgBinary)
                        return `
                        <p class="py-4" style="text-align: center;">
                            <img
                                src=${imgBase}
                                style="max-height: 340px;"
                            />
                        </p>`
                    })}
                </div>
                `
            }

            f_html_student_list += `
            <div>
                <p style='font-weight: 700; margin:0in;font-size:20px;font-family:"Angsana New",serif;margin-top:12.0pt;text-align:center;'>
                    <strong>
                        <span style='font-family:"TH Niramit AS";font-size: 20px;'>
                            รายชื่อนักเรียน ชั้น ${m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}
                        </span>
                    </strong>
                </p>
                <br />
                ${info_teaching_dare}
                <br />
                ${form_student_lists}
                <br />
                <p style='font-weight: 700; margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'>
                    <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>
                        ตรวจแล้วถูกต้อง
                    </span>
                </p>
                <br />
                <br />
                <p style='font-weight: 700; margin: 0in; font-size: 14px; font-family: "TH Niramit AS"; text-align: center; margin-bottom: 0.5rem;'>
                    ลงชื่อ
                    <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>
                        ..................................................................
                    </span>
                </p>
                <p style='font-weight: 700; margin: 0in; font-size: 14px; font-family: "Angsana New", serif; text-align: center;'>
                    <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                        ( ${user_profile.m_rank ? user_profile.m_rank.m_rank_name : ''} ${user_profile.first_name ? user_profile.first_name : '.........................'} ${user_profile.last_name ? user_profile.last_name : '.........................'} )
                    </span>
                </p>
                <p style='font-weight: 700; margin: 0in; font-size: 14px; font-family: "Angsana New", serif; text-align: center;'>
                    <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                        ตำแหน่ง ${user_profile.position_name ? user_profile.position_name : '.................................'}
                    </span>
                </p>
            </div>
            `


            f_html += `
                <div style='max-height: 900.24px; height: 900.24px; width: 604px;' class="p-0 m-0">
                    <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'>
                        <strong>
                            <span style='font-size: 20px; font-family: "TH Niramit AS";'>
                            แผนการสอน โครงการการศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน
                            </span>
                        </strong>
                        <strong>
                            <span style='font-family: "TH Niramit AS";font-size: 20px;'>
                                (D.A.R.E.ประเทศไทย)
                            </span>
                        </strong>
                    </p>
                    <br />
                    ${info_teaching_dare}
                    <br />
                    <table style="width:600px; border-collapse:collapse;border:none;">
                        <tbody>
                            <tr>
                                <td style="width: 4%;border: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                        <strong>
                                            <span style='font-family: "TH Niramit AS";font-size: 14px;'>
                                                ลำดับ
                                            </span>
                                        </strong>
                                    </p>
                                </td>
                                <td style="width: 18%;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                        <strong>
                                            <span style='font-family:"TH Niramit AS";font-size: 14px;'>
                                                วัน เดือน ปี
                                            </span>
                                        </strong>
                                    </p>
                                </td>
                                <td style="width: 18%;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                        <strong>
                                            <span style='font-family:"TH Niramit AS";font-size: 14px;'>
                                                เวลา
                                            </span>
                                        </strong>
                                    </p>
                                </td>
                                <td style="width: 60%;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:14px;font-family:"Angsana New",serif;text-align:center;'>
                                        <strong>
                                            <span style='font-family:"TH Niramit AS";font-size: 14px;'>
                                                เนื้อหาที่ทำการสอน
                                            </span>
                                        </strong>
                                    </p>
                                </td>
                            </tr>
                            ${form_lessons_table}
                        </tbody>
                    </table>
                    <br />
                    ${signature_teacher_dare}
                    <p class="pb-0 mb-0" style='font-weight: 700; margin: 0in; font-size: 14px; font-family: "TH Niramit AS"; text-align: center;'>
                        ลงชื่อ
                        <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>
                            ...........................................................................
                        </span>
                        ผู้ดำเนินโครงการ
                    </p>
                    <p class="pb-0 mb-0" style='font-weight: 700; margin: 0in; font-size: 14px; font-family: "Angsana New", serif; text-align: center;'>
                        <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                            (${user_profile ? `${user_profile && user_profile.m_rank ? user_profile.m_rank.m_rank_name : ''} ${user_profile.first_name ? user_profile.first_name : ''} ${user_profile.last_name ? user_profile.last_name : ''}` : `.....................................`})
                        </span>
                    </p>
                    <p style='font-weight: 700; margin: 0in; font-size: 14px; font-family: "Angsana New", serif; text-align: center;'>
                        <span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 14px;'>
                            ตำแหน่ง ${user_profile.position_name ? user_profile.position_name : '.................................'}
                        </span>
                    </p>
                </div>
                
                ${form_teaching_plan}

                ${f_html_teaching_actual_imgs}
                
                ${f_html_student_list}
                
            `
        }
    }

    return `<!DOCTYPE html>
    <html>
    
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title></title>
    </head>
    
    <body style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">
        ${f_html}
    </body>
    
    </html>`
}